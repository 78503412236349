import React, { useState, useEffect } from "react";
import axios from "axios";
import {
  GoogleMap,
  InfoWindow,
  Marker,
  useJsApiLoader,
} from "@react-google-maps/api";

const API = axios.create({ baseURL: "https://www.95424118.xyz" });

window.onload = function () {
  setTimeout(function () {
    window.scrollTo(0, 50);
  }, 100);
};


function MyMap() {

  const containerStyle = {
    position: "relative",
    width: "100vw",
    height: "100vh",
  };

  const center = {
    lat: 35.633862,
    lng: -117.679367,
  };


  const styles = {
    default: [],
    hide: [
      {
        featureType: "poi.business",
        stylers: [{ visibility: "off" }],
      },
      {
        featureType: "transit",
        elementType: "labels.icon",
        stylers: [{ visibility: "off" }],
      },
    ],
  };

  const [map, setMap] = useState(null);
  const [availableDates, setAvailableDates] = useState([]);
  const [initialCrimes, setInitialCrimes] = useState([]);

  let markersArray = [];

  const { isLoaded } = useJsApiLoader({
    id: "google-map-script",
    googleMapsApiKey: "AIzaSyB2-QnVXqBPn1u6YVTtPvae_aoWdRFUrKU",
  });


  const onUnmount = React.useCallback(function callback(map) {
    console.log("map unmounted");
    setMap(null)
  }, []);


  const initMap = (e) => {
    e.setOptions({ styles: styles["hide"] });
    setMap(e);
  }

  useEffect(() => {
    if (map != null) {
      console.log("calling API");
      makeApiCall();
    }
  }, [map]);

  useEffect(() => {
    if (availableDates.length > 0 && initialCrimes.length > 0) {
      console.log("available dates called and initialCrimes displayed");

      initialCrimes.forEach(crime => {
        displayMarker(crime.location, crime.charges);
      });
    }
  }, [availableDates, initialCrimes]);



  async function makeApiCall() {
    try {
      const { data } = await API.get('/crimes');

      const parsedCrimes = JSON.parse(data.log.crimes);

      setInitialCrimes(parsedCrimes);
      setAvailableDates(data.available);

    } catch (error) {
      console.log(error);
    }

  }

  async function requestSpecific(e) {

    clearMarkers();

    try {
      const { data } = await API.get('/crimes/' + e.target.value);

      const parsedCrimes = JSON.parse(data.log.crimes);

      parsedCrimes.forEach(crime => {
        displayMarker(crime.location, crime.charges);
      });
    } catch (error) {
      console.log(error);
    }
  }

  function displayMarker(location, charges) {

    const marker = new google.maps.Marker({
      map,
      position: location,
    });


    const infowindow = new google.maps.InfoWindow({
      content: "<h2>" + charges + "</h2>",
    });

    marker.addListener("click", () => {
      infowindow.open({
        anchor: marker,
        map,
      });
    });

    markersArray.push(marker);
  }

  function clearMarkers() {
    while (markersArray.length) {
      markersArray.pop().setMap(null);
    }
  }

  return isLoaded ? (
    <>
      <div
        style={{
          position: "absolute",
          zIndex: 0,
          width: "100vw", // or you can use width: '100vw'
          height: "100vh" // or you can use height: '100vh'
        }}
      >
        <div
          style={{
            zIndex: 1,
            position: "absolute",
            top: "8%",
            left: "44%",
            backgroundColor: "orange", // you can use any color value
          }}
        >
          <label style={{ paddingLeft: 5 }}>Week of: </label>
          <select style={{ fontSize: "20px" }} id="selectOption" onChange={requestSpecific}>
            {availableDates.map(date => (
              <option key={date} value={date}>{date}</option>
            ))}
          </select>
        </div>

        <GoogleMap
          mapContainerStyle={containerStyle}
          center={center}
          zoom={14}
          onLoad={(myMap) => initMap(myMap)}
          onUnmount={onUnmount}
        >
        </GoogleMap>
        <form style={{ zIndex: 1, position: "absolute", bottom: "2%", left: "2%" }} action="https://www.paypal.com/donate" method="post" target="_blank">
          <input type="hidden" name="business" value="XEZAJTNSDZ47J" />
          <input type="hidden" name="no_recurring" value="1" />
          <input type="hidden" name="currency_code" value="USD" />
          <input type="image" src="https://www.paypalobjects.com/en_US/i/btn/btn_donateCC_LG.gif" border="0" name="submit" title="PayPal - The safer, easier way to pay online!" alt="Donate with PayPal button" />
          <img alt="" border="0" src="https://www.paypal.com/en_US/i/scr/pixel.gif" width="1" height="1" />
        </form>
      </div>
    </>
  ) : (
    <></>
  );
}

export default React.memo(MyMap);
