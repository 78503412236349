import logo from './logo.svg';
import './App.css';
import MyMap from './MyMap';

function App() {
  return (
    <div className="App">

      <MyMap />

    </div>
  );
}

export default App;
